import { uploadWasmSlice } from "@/src/components/ledger/Upload/Upload.slice";
import { dashboardSlice } from "@/src/redux/slices/dashboard.slice";
import { globalSlice } from "@/src/redux/slices/global.slice";
import { transientSlice } from "@/src/redux/slices/transient.slice";

import { explorerSlice } from "@/src/app/(main)/(explorer)/explore/Explorer.slice";

import {
  themeSlice,
  alertSlice,
  notificationSlice,
  authSlice,
  userSlice,
  chainWarningSlice,
  itemSlice,
} from "nftx-core";

export default {
  global: globalSlice.reducer,
  transient: transientSlice.reducer,
  auth: authSlice.reducer,
  uploadWasm: uploadWasmSlice.reducer,
  dashboard: dashboardSlice.reducer,
  user: userSlice.reducer,
  alert: alertSlice.reducer,
  notification: notificationSlice.reducer,
  item: itemSlice.reducer,
  chainWarning: chainWarningSlice.reducer,
  theme: themeSlice.reducer,
  explorer: explorerSlice.reducer,
};
