// OLD CODE. Check it's the same
// import {
//   createAsyncThunk,
//   createListenerMiddleware,
//   createSlice,
// } from "@reduxjs/toolkit";
// import { client } from "@/src/urlq/client";
// import { uploadWasm } from "@/src/urlq/mutations/upload";
// //@ts-ignore
// import { v4 as uuidv4 } from "uuid";

// export interface UploadState {
//   uploadList: Array<{ id: string; status: FileStatus; meta: any; binary: any }>;
// }

// export enum FileStatus {
//   ACCEPTED = "ACCEPTED", // File is accepted and ready to be uploaded
//   REJECTED = "REJECTED", // File is rejected and will not be uploaded
//   PENDING = "PENDING", // File is being uploaded
//   UPLOADED = "UPLOADED", // File is uploaded
//   FAILED = "FAILED", // File failed to upload
// }

// const initialState = {
//   uploadList: [],
// };

// export const uploadFile = createAsyncThunk(
//   "upload/uploadFile",
//   async ({ id }: any, thunkAPI: any) => {
//     try {
//       // get the file from the uploadList
//       const state = thunkAPI.getState() as any;
//       const file = state.uploadWasm.uploadList.find(
//         (item: any) => item.id === id
//       ).binary;

//       const data = await client.mutation(uploadWasm, { file: file });

//       if (data.error) {
//         return thunkAPI.rejectWithValue(data.error);
//       }

//       //@ts-ignore
//       return data.data.uploadWasm;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

// // export const addMeta = createAsyncThunk(
// //     "upload/addMeta",
// //     async({id, meta}:any, thunkAPI: any) => {
// //         try {
// //             let index = thunkAPI.getState().uploadWasm.uploadList.findIndex((item: any) => item.id === id);
// //             thunkAPI.getState().uploadWasm.uploadList[index].meta = meta;
// //
// //             return true;
// //         } catch (error) {
// //             throw error;
// //         }
// //     }
// // );

// // Create Listener Middleware
// const listenerMiddleware = createListenerMiddleware();
// export const uploadListenerMiddleware = listenerMiddleware;

// // Watch for signIn.fulfilled and dispatch fetchUser
// listenerMiddleware.startListening({
//   actionCreator: uploadFile.fulfilled,
//   effect: async (action, listenerApi) => {
//     let id = action.meta.arg.id;
//     listenerApi.dispatch(
//       uploadWasmSlice.actions.addMeta({ id, meta: action.payload })
//     );
//   },
// });

// export const uploadWasmSlice = createSlice({
//   name: "uploadWasm",
//   initialState,
//   reducers: {
//     addUpload: (state: any, action: any) => {
//       let id = uuidv4();

//       let status = FileStatus.ACCEPTED;
//       console.log("here");

//       // check file size
//       if (action.payload.size > 10000000000) {
//         status = FileStatus.REJECTED;
//       }

//       // check file type
//       if (action.payload.type !== "application/wasm") {
//         status = FileStatus.REJECTED;
//       }

//       let uploadEntry = {
//         id,
//         status,
//         binary: action.payload,
//       };

//       state.uploadList.push(uploadEntry);

//       return state;
//     },
//     removeUpload: (state: any, action: any) => {
//       let ids = action.payload;
//       ids.forEach((id: any) => {
//         let index = state.uploadList.findIndex((item: any) => item.id === id);
//         state.uploadList.splice(index, 1);
//       });

//       return state;
//     },
//     addMeta: (state: any, action: any) => {
//       let id = action.payload.id;
//       let meta = action.payload.meta;

//       let index = state.uploadList.findIndex((item: any) => item.id === id);
//       state.uploadList[index].meta = meta;

//       return state;
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(uploadFile.rejected, (state: any, action: any) => {
//       let id = action.meta.arg.id;

//       let index = state.uploadList.findIndex((item: any) => item.id === id);
//       state.uploadList[index].status = FileStatus.FAILED;

//       return state;
//     });

//     builder.addCase(uploadFile.pending, (state: any, action: any) => {
//       let id = action.meta.arg.id;

//       let index = state.uploadList.findIndex((item: any) => item.id === id);
//       state.uploadList[index].status = FileStatus.PENDING;

//       return state;
//     });

//     builder.addCase(uploadFile.fulfilled, (state: any, action: any) => {
//       let id = action.meta.arg.id;

//       let index = state.uploadList.findIndex((item: any) => item.id === id);
//       state.uploadList[index].status = FileStatus.UPLOADED;

//       return state;
//     });
//   },
// });

import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
} from "@reduxjs/toolkit";
import { client } from "@/src/urlq/client";
import { uploadWasm } from "@/src/urlq/mutations/upload";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";

export interface UploadState {
  uploadList: Array<{ id: string; status: FileStatus; meta: any; binary: any }>;
}

export enum FileStatus {
  ACCEPTED = "ACCEPTED", // File is accepted and ready to be uploaded
  REJECTED = "REJECTED", // File is rejected and will not be uploaded
  PENDING = "PENDING", // File is being uploaded
  UPLOADED = "UPLOADED", // File is uploaded
  FAILED = "FAILED", // File failed to upload
}

const initialState = {
  uploadList: [],
};

export const uploadFile = createAsyncThunk(
  "upload/uploadFile",
  async ({ id }: any, thunkAPI: any) => {
    try {
      // get the file from the uploadList
      const state = thunkAPI.getState() as any;
      const file = state.uploadWasm.uploadList.find(
        (item: any) => item.id === id
      ).binary;

      const data = await client.mutation(uploadWasm, { file: file });

      if (data.error) {
        return thunkAPI.rejectWithValue(data.error);
      }

      //@ts-ignore
      return data.data.uploadWasm;
    } catch (error) {
      throw error;
    }
  }
);

// export const addMeta = createAsyncThunk(
//     "upload/addMeta",
//     async({id, meta}:any, thunkAPI: any) => {
//         try {
//             let index = thunkAPI.getState().uploadWasm.uploadList.findIndex((item: any) => item.id === id);
//             thunkAPI.getState().uploadWasm.uploadList[index].meta = meta;
//
//             return true;
//         } catch (error) {
//             throw error;
//         }
//     }
// );

// Create Listener Middleware
const listenerMiddleware = createListenerMiddleware();
export const uploadListenerMiddleware = listenerMiddleware;

// Watch for signIn.fulfilled and dispatch fetchUser
listenerMiddleware.startListening({
  actionCreator: uploadFile.fulfilled,
  effect: async (action, listenerApi) => {
    let id = action.meta.arg.id;
    listenerApi.dispatch(
      uploadWasmSlice.actions.addMeta({ id, meta: action.payload })
    );
  },
});

export const uploadWasmSlice = createSlice({
  name: "uploadWasm",
  initialState,
  reducers: {
    addUpload: (state: any, action: any) => {
      let id = uuidv4();

      let status = FileStatus.ACCEPTED;

      console.log("here");

      // check file size
      // if (action.payload.size > 500_000_000_000) {
      //   status = FileStatus.REJECTED;
      // }

      // check file type
      if (action.payload.type !== "application/wasm") {
        status = FileStatus.REJECTED;
      }

      let uploadEntry = {
        id,
        status,
        binary: action.payload,
      };

      state.uploadList.push(uploadEntry);

      return state;
    },
    removeUpload: (state: any, action: any) => {
      let ids = action.payload;
      ids.forEach((id: any) => {
        let index = state.uploadList.findIndex((item: any) => item.id === id);
        state.uploadList.splice(index, 1);
      });

      return state;
    },
    addMeta: (state: any, action: any) => {
      let id = action.payload.id;
      let meta = action.payload.meta;

      let index = state.uploadList.findIndex((item: any) => item.id === id);
      state.uploadList[index].meta = meta;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadFile.rejected, (state: any, action: any) => {
      let id = action.meta.arg.id;

      let index = state.uploadList.findIndex((item: any) => item.id === id);
      state.uploadList[index].status = FileStatus.FAILED;

      return state;
    });

    builder.addCase(uploadFile.pending, (state: any, action: any) => {
      let id = action.meta.arg.id;

      let index = state.uploadList.findIndex((item: any) => item.id === id);
      state.uploadList[index].status = FileStatus.PENDING;

      return state;
    });

    builder.addCase(uploadFile.fulfilled, (state: any, action: any) => {
      let id = action.meta.arg.id;

      let index = state.uploadList.findIndex((item: any) => item.id === id);
      state.uploadList[index].status = FileStatus.UPLOADED;

      return state;
    });
  },
});
