import {
  //@ts-ignore
  HeaderGlobalAction,
  //@ts-ignore
  HeaderPanel,
  //@ts-ignore
  Switcher,
  //@ts-ignore
  SwitcherDivider,
} from "@carbon/react";
//@ts-ignore
import {
  Switcher as SwitcherIcon,
  Email as EmailIcon,
  Rule as RuleIcon,
  Policy as PolicyIcon,
  LogoDiscord,
  LogoMastodon,
  InformationSquare as InformationSquareIcon,
  SearchLocate as SearchLocateIcon,
  Store as StoreIcon,
  Help as HelpIcon,
  CharacterFraction as CharacterFractionIcon,
  WatsonHealthDna as WatsonHealthDnaIcon,
  //@ts-ignore
} from "@carbon/icons-react";
import {
  transientSlice,
  toggleMainMenu,
} from "@/src/redux/slices/transient.slice";
import { connect } from "react-redux";
import { SwitcherItem } from "nftx-core";

const MenuSwitcher = ({ menuExpanded, toggleMenu }: any) => {
  return (
    <HeaderGlobalAction
      aria-label="Menu"
      isActive={menuExpanded}
      tooltipAlignment="end"
      onClick={toggleMenu}
    >
      <SwitcherIcon size={22} />
    </HeaderGlobalAction>
  );
};

const MenuPanel = ({ menuExpanded }: any) => {
  return (
    <HeaderPanel
      expanded={menuExpanded}
      href="#switcher-button"
      addFocusListeners={false}
      onHeaderPanelFocus={() => {}}
    >
      <Switcher aria-label="Switcher Container">
        <SwitcherItem href="/explore" aria-label="">
          <SearchLocateIcon size={22} />
          Explore NFTx
        </SwitcherItem>
        <SwitcherItem
          aria-label=""
          href="https://nftxtrade.com"
          outbound={true}
        >
          <StoreIcon size={22} />
          NFTx Market
        </SwitcherItem>
      </Switcher>
      <SwitcherDivider />
      <Switcher aria-label="Switcher Container">
        <SwitcherItem aria-label="" href="/nftx-intro">
          <HelpIcon size={22} />
          What is NFTx?
        </SwitcherItem>
        <SwitcherItem aria-label="" href="/fractional-nftx">
          <CharacterFractionIcon size={22} />
          Fractional NFTx
        </SwitcherItem>
        <SwitcherItem aria-label="" href="/the-ledger">
          <WatsonHealthDnaIcon size={22} />
          The Ledger
        </SwitcherItem>
      </Switcher>
      <SwitcherDivider />
      <Switcher aria-label="Switcher Container">
        <SwitcherItem aria-label="" href="/about">
          <InformationSquareIcon size={22} />
          About NFTx
        </SwitcherItem>
        <SwitcherItem aria-label="" href="/terms">
          <RuleIcon size={22} />
          Service Terms
        </SwitcherItem>
        <SwitcherItem aria-label="" href="/policy">
          <PolicyIcon size={22} />
          Privacy Policy
        </SwitcherItem>
      </Switcher>
      <SwitcherDivider />
      <Switcher aria-label="Switcher Container">
        <SwitcherItem
          href="https://discord.gg/5235xHYx"
          aria-label=""
          outbound={true}
        >
          <LogoDiscord size={22} />
          Discord
        </SwitcherItem>
        <SwitcherItem
          href="https://mastodon.nftxledger.com"
          aria-label=""
          outbound={true}
        >
          <LogoMastodon size={22} />
          Mastodon
        </SwitcherItem>
        <SwitcherItem href="mailto:contact@nftxledger.com" aria-label="">
          <EmailIcon size={22} />
          Contact Us
        </SwitcherItem>
      </Switcher>
    </HeaderPanel>
  );
};

const mapStateToProps = (state: any) => ({
  menuExpanded: state.transient.menus.main,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleMenu: () => dispatch(toggleMainMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSwitcher);

const connectedMenuPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuPanel);

export { connectedMenuPanel as MenuPanel };
