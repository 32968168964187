import { Client, cacheExchange, fetchExchange } from "urql";
import RootState from "@/src/redux/Store";

export const client = new Client({
  url: "https://wasm-gateway.aderbal.workers.dev",
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    const token = RootState.getState().auth.authToken;

    return {
      headers: { authorization: token ? `Bearer ${token}` : "" },
    };
  },
});
