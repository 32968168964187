"use client";

import "@/src/styles/main.scss";

import { pkg } from "@carbon/ibm-products/es/settings";
pkg.prefix = "ibmproducts";
pkg.component.ActionBar = true;

import SEO from "@/next-seo.config";
import { DefaultSeo } from "next-seo";
import { Provider } from "react-redux";
import store from "@/src/redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "@/src/redux/Store";

import {
  ThemeWrapper,
  AlertContainer,
  ErrorBoundary,
  NoScript,
  UrqlProvider,
  WalletWrapper,
  NetworkStatus,
} from "nftx-core";

import Modals from "@/src/components/Modals";
import Header from "@/src/components/Header";
import { useEffect } from "react";

export default function Layout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    navigator.serviceWorker
      .register("/sw.js")
      .then((registration) => {
        // console.log("Service Worker Registered");
        // console.log(registration);
      })
      .catch((err) => {
        // console.log("Service Worker Registration Failed");
        // console.log(err);
      });
  });

  const endpoint = "https://wasm-gateway.aderbal.workers.dev";
  const token = "";

  return (
    <>
      <NoScript />
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <UrqlProvider>
              <ThemeWrapper>
                <DefaultSeo {...SEO} />
                <WalletWrapper>
                  <Header />
                  <Modals />
                  <AlertContainer />
                  {children}
                  <NetworkStatus />
                </WalletWrapper>
              </ThemeWrapper>
            </UrqlProvider>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </>
  );
}
