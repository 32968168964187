import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
} from "@reduxjs/toolkit";
import { client } from "@/src/urlq/client";
import { queryArtifacts } from "@/src/urlq/queries/artifacts";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";

export enum fetchArtifactsStatus {
  PENDING,
  FULFILLED,
  REJECTED,
}

export interface UploadState {
  artifactList: Array<{ id: string }>;
  fetchArtifacts: fetchArtifactsStatus;
}

const initialState = {
  artifactList: [],
  fetchArtifacts: fetchArtifactsStatus.PENDING,
};

// Async Thunks
export const fetchArtifacts = createAsyncThunk(
  "user/fetchArtifacts",
  async () => {
    try {
      const { data } = await client.query(queryArtifacts, {});

      //console.log(data);

      return data.artifacts;
    } catch (error) {
      console.log(error);
    }
  },
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArtifacts.fulfilled, (state, action) => {
      state.fetchArtifacts = fetchArtifactsStatus.FULFILLED;

      state.artifactList = action.payload;
    });
    builder.addCase(fetchArtifacts.rejected, (state, action) => {
      state.fetchArtifacts = fetchArtifactsStatus.REJECTED;
    });
    builder.addCase(fetchArtifacts.pending, (state, action) => {
      state.fetchArtifacts = fetchArtifactsStatus.PENDING;

      state.artifactList = [];
    });
  },
});
