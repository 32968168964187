import {
  //@ts-ignore
  Modal,
  Form,
  //@ts-ignore
  FormGroup,
  TextInput,
  //@ts-ignore
  Stack,
  Button,
  //@ts-ignore
  InlineLoading,
  SkeletonText,
  Toggle,
  //@ts-ignore
  FormLabel,
} from "@carbon/react";
import { connect } from "react-redux";
import { useState } from "react";
import { modalsSlice } from "@/src/redux/slices/modals.slice";

const TrackArtifact = ({ modalState, closeModal }: any) => {
  return (
    <Modal
      modalHeading="Track NFTx Artifacts"
      primaryButtonText="Save"
      secondaryButtonText="Close"
      open={modalState}
      passiveModal={true}
      onRequestClose={() => closeModal()}
    >
      <Form>
        <Stack gap={7}>
          <FormGroup
            legendText=""
            style={{
              display: "flex",
              width: "300px",
            }}
          >
            <Button kind="secondary" onClick={closeModal} disabled={false}>
              Cancel
            </Button>
            <Button onClick={() => {}}>Add to Account</Button>
          </FormGroup>
        </Stack>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  //modalState: state.modals.trackArtifact,
});

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => dispatch(modalsSlice.actions.closeTrackArtifact()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackArtifact);
