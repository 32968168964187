export const queryArtifacts = `
query {
    artifacts {
        artifactId
        walletId
        size
        uploadedAt
        metadata {
            name
        }
    }
}
`;
