import MenuSwitcher, { MenuPanel } from "@/src/components/Header/MenuSwitcher";

import {
  ThemeSwitcher,
  NotificationBar,
  ConnectButton,
  AuthState,
  UserTab,
  UserPanel,
  SwitcherItem,
} from "nftx-core";

import { connect } from "react-redux";
import { RootState } from "@/src/redux/Store";

import {
  //@ts-ignore
  Header as CarbonHeader,
  //@ts-ignore
  HeaderName,
  //@ts-ignore
  HeaderGlobalBar,
  SideNavMenu,
} from "@carbon/react";

import {
  Upload as UploadIcon,
  UserData as UserDataIcon,
} from "@carbon/icons-react";
import Link from "next/link";

function Header({ authState }: { authState: AuthState }) {
  return (
    <CarbonHeader aria-label="Header">
      <Link href="/" passHref legacyBehavior>
        <div style={{ cursor: "pointer" }}>
          <HeaderName prefix="NFTx">Ledger</HeaderName>
        </div>
      </Link>
      <HeaderGlobalBar>
        <ThemeSwitcher />
        <ConnectButton />
        {authState === AuthState.SIGNED_IN ? <NotificationBar /> : <></>}
        <UserTab />
        <MenuSwitcher />
      </HeaderGlobalBar>
      <UserPanel>
        <SideNavMenu title="NFTx" defaultExpanded={true}>
          <SwitcherItem href="/uploader" aria-label="Upload NFTx">
            <UploadIcon size={22} />
            Upload NFTx
          </SwitcherItem>
          <SwitcherItem href="/my-dashboard" aria-label="My NFTx">
            <UserDataIcon size={22} />
            My NFTxs
          </SwitcherItem>
        </SideNavMenu>
      </UserPanel>
      <MenuPanel />
    </CarbonHeader>
  );
}

const mapStateToProps = (state: RootState) => ({
  authState: state.auth.authState,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
