import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
} from "@reduxjs/toolkit";

export interface MenuItems {
  main: Boolean;
}

export interface TransientItems {
  menus: MenuItems;
}

// Initial state
const initialState: TransientItems = {
  menus: {
    main: false,
  },
};

export const openMainMenu = createAsyncThunk(
  "transient/openMainMenu",
  async (_, thunkApi) => {
    thunkApi.dispatch({ type: "global/closePanels" });
    thunkApi.dispatch(transientSlice.actions.openMenu());
  }
);

export const closeMainMenu = createAsyncThunk(
  "transient/closeMainMenu",
  async (_, thunkApi) => {
    thunkApi.dispatch(transientSlice.actions.closeMenu());
  }
);

export const toggleMainMenu = createAsyncThunk(
  "transient/toggleMainMenu",
  async (_, thunkApi) => {
    if (!thunkApi.getState().transient.menus.main) {
      thunkApi.dispatch({ type: "global/closePanels" });
    }
    thunkApi.dispatch(transientSlice.actions.toggleMenu());
  }
);

// create Listener Middleware
export const transientListenerMiddleware = createListenerMiddleware();

transientListenerMiddleware.startListening({
  type: "global/closePanels",
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(transientSlice.actions.closeMenu());
  },
});

export const transientSlice = createSlice({
  name: "transient",
  initialState,
  reducers: {
    openMenu: (state: TransientItems) => {
      state.menus.main = true;
    },
    closeMenu: (state: TransientItems) => {
      state.menus.main = false;
    },
    toggleMenu: (state: TransientItems) => {
      state.menus.main = !state.menus.main;
    },
  },
});
