import { createSlice } from "@reduxjs/toolkit";

export interface GlobalItems {}

// Initial state
const initialState: GlobalItems = {};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {},
});
