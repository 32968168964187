import { configureStore, combineReducers } from "@reduxjs/toolkit";

import reducers from "@/src/redux/slices";

import {
  authListenerMiddleware,
  userListenerMiddleware,
  itemListenerMiddleware,
  notificationListenerMiddleware,
} from "nftx-core";
import { uploadListenerMiddleware } from "@/src/components/ledger/Upload/Upload.slice";
import { transientListenerMiddleware } from "@/src/redux/slices/transient.slice";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["uploadWasm", "transient"],
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "uploadWasm/addUpload",
        ],
        ignoredPaths: ["uploadWasm.uploadList"],
      },
    })
      .prepend(authListenerMiddleware.middleware)
      .prepend(userListenerMiddleware.middleware)
      .prepend(uploadListenerMiddleware.middleware)
      .prepend(itemListenerMiddleware.middleware)
      .prepend(transientListenerMiddleware.middleware)
      .prepend(notificationListenerMiddleware.middleware),
  devTools: true,
});

if (typeof window !== "undefined") {
  //@ts-ignore
  window.store = store;
}

// Export the store
export default store;
// Export the store types
export type RootState = ReturnType<typeof store.getState>;
// Export the persisted store
export const persistor = persistStore(store);
