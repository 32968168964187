export const uploadWasm = `
mutation ($file: Upload!) {
    uploadWasm(
        file: $file
    ) {
        name,
        hash
    }
}
`;
