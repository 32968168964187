import { queryExplorerArtifacts } from "./graphql/queries/explorer";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getClient } from "nftx-core";

export interface artifact {
  artifactId: string;
  walletId: string;
  size: number;
  uploadedAt: string;
  metadata: {
    name: string;
    authors: string[];
  };
}

export interface ExplorerState {
  artifacts: artifact[];
  loading: boolean;
}

const initialState: ExplorerState = {
  artifacts: [],
  loading: false,
};

export const fetchExplorerArtifacts = createAsyncThunk(
  "explorer/fetchExplorerArtifacts",
  async () => {
    const client = getClient();
    const { data } = await client.query(queryExplorerArtifacts, {});

    return data.explorerArtifacts;
  }
);

export const explorerSlice = createSlice({
  name: "explorer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExplorerArtifacts.pending, (state) => {
      state.loading = true;

      return state;
    });
    builder.addCase(fetchExplorerArtifacts.fulfilled, (state, action) => {
      state.artifacts = action.payload;
      state.loading = false;

      return state;
    });
  },
});
