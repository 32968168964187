import ModalTrackArtifact from "@/src/components/ledger/NFTx/TrackArtifact";
import {
  ModalEditProfile,
  ModalChainWarning,
  ModalSignMessage,
} from "nftx-core";
export default function Modals() {
  return (
    <>
      <ModalEditProfile />
      <ModalTrackArtifact />
      <ModalChainWarning />
      <ModalSignMessage />
    </>
  );
}
