import { createSlice } from "@reduxjs/toolkit";

export interface ModalsState {
  signin: Boolean;
  editProfile: Boolean;
  trackArtifact: Boolean;
}

// Initial state
const initialState: ModalsState = {
  signin: false,
  editProfile: false,
  trackArtifact: false,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openSignin: (state: ModalsState) => {
      state.signin = true;

      return state;
    },
    closeSignin: (state: ModalsState) => {
      state.signin = false;

      return state;
    },
    openEditProfile: (state: ModalsState) => {
      state.editProfile = true;

      return state;
    },
    closeEditProfile: (state: ModalsState) => {
      state.editProfile = false;

      return state;
    },
    openTrackArtifact: (state: ModalsState) => {
      state.trackArtifact = true;

      return state;
    },
    closeTrackArtifact: (state: ModalsState) => {
      state.trackArtifact = false;

      return state;
    },
  },
});
