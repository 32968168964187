export const queryExplorerArtifacts = `
query {
  explorerArtifacts {
    artifactId
    walletId
    size
    uploadedAt
    metadata {
      name
    }
  }
}
`;
